/* You can add global styles to this file, and also import other style files */
@import "typeface-poppins";
@tailwind base;
@tailwind components;
@tailwind utilities;


.screen {
    @apply md:max-w-screen-md lg:md:max-w-screen-lg xl:md:max-w-screen-xl mx-auto
}
